import React from "react"

import Layout from "../components/layout"
import BirchModal from "../components/birchTreeMod"
import SEO from "../components/seo"
import { Row, Col, Image, OverlayTrigger } from "react-bootstrap"

import birchtree from "../assets/pictures/artwork/birchtree.jpg"

import maze from "../assets/pictures/artwork/maze.jpg"
import illusion from "../assets/pictures/artwork/illusion.jpg"
import lenaflowers from "../assets/pictures/artwork/lena-flowers.jpg"
import skeleton from "../assets/pictures/artwork/skeleton.jpg"

import bookcase from "../assets/pictures/artwork/bookcase.jpg"

import taco from "../assets/pictures/artwork/taco.jpg"

import christmas3 from "../assets/pictures/artwork/christmas2020-3.jpg"
import christmas2 from "../assets/pictures/artwork/christmas2020-2.jpg"
import christmas4 from "../assets/pictures/artwork/christmas2020-4.jpg"
import christmas5 from "../assets/pictures/artwork/christmas2020-5.jpg"

import sidetable from "../assets/pictures/artwork/sidetable.jpg"

import piano from "../assets/pictures/artwork/piano.jpg"
import lion from "../assets/pictures/artwork/lion.jpg"
import table from "../assets/pictures/artwork/table.jpg"

class ArtPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      show: false
    }
    this.handleShow = this.handleShow.bind(this)
  }
 
  render(){
  return(
      <Layout>
        <SEO title="Artwork" />
        <div style={{ height: "90vh", overflowY: "scroll" }}>
        <Row>
          <Col>
            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Birch Trees Painting- 2021
              </div>}
            >
              <Image style={{marginBottom: "2vh"}} src={birchtree} fluid onClick={this.handleShow}/>
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Cardboard Bookcase - 2021
              </div>}
            >
              <Image style={{marginBottom: "2vh"}} src={bookcase} fluid />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Crochet Taco Pillow - 2021
              </div>}
            >
              <Image style={{marginBottom: "2vh"}} src={taco} fluid />
            </OverlayTrigger>
          </Col>

          <Col>
            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Paper California Tree Poppies - 2021
              </div>}
            >
              <Image style={{marginBottom: "2vh"}} src={lenaflowers} fluid />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Maze Painting - 2021
              </div>}
            >
              <Image style={{marginBottom: "2vh"}} src={maze} fluid />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Cardboard Guitar Giftbox - 2020
              </div>}
            >
              <Image style={{marginBottom: "2vh"}} src={christmas3} fluid />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Cardboard Piano - 2014
              </div>}
            >
              <Image src={piano} fluid />
            </OverlayTrigger>
          </Col>

          <Col>
            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Illusion Painting - 2021
              </div>}
            >
              <Image style={{marginBottom: "2vh"}} src={illusion} fluid />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Cheeseburger Gift Wrapping - 2020
              </div>}
            >
              <Image style={{marginBottom: "2vh"}} src={christmas5} fluid />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Cardboard Toilet Giftbox - 2020
              </div>}
            >
              <Image style={{marginBottom: "2vh"}} src={christmas4} fluid />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Cardboard Magazine Collage Table - 2014
              </div>}
            >
              <Image src={table} fluid />
            </OverlayTrigger>
          </Col>
        
          <Col>
            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Skeleton Painting - 2021
              </div>}
            >
              <Image style={{marginBottom: "2vh"}} src={skeleton} fluid />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Hershey's Chocolate Bar Gift Wrapping - 2021
              </div>}
            >
              <Image style={{marginBottom: "2vh"}} src={christmas2} fluid />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Cardboard Sidetable - 2017
              </div>}
            >
              <Image style={{marginBottom: "2vh"}} src={sidetable} fluid />
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom-end"
              delay={{ show: 250, hide: 400 }}
              overlay={<div
                style={{
                  backgroundColor: 'rgba(25, 25, 25, 0.85)',
                  padding: '2px 10px',
                  color: 'white',
                  position: 'absolute'
                }}
              >
                Paper Mache Glitter Lion Statue - 2014
              </div>}
            >
              <Image src={lion} fluid />
            </OverlayTrigger>
          </Col>
          
        </Row>
        <BirchModal show={this.state.show} onClose={() => this.setState({show:false})}>
          <Image style={{marginBottom: "2vh"}} src={birchtree} fluid onClick={this.handleShow}/>
        </BirchModal>
        </div>
      </Layout>
    )
  }
  handleShow(e){
    this.setState({show:true})
  }
}

export default ArtPage
