import React from "react"
import { Modal, Row, Col, Image } from "react-bootstrap"
import birchtree from "../assets/pictures/artwork/birchtree.jpg"

const BirchModal = ({ show, onClose}) => (
    <Modal
    show={show}
    onHide={onClose}
    dialogClassName="my-modal"
    centered
    >
        <Row>
            <Col>
                <Image src={birchtree} fluid/>
            </Col>
            <Col>
            <Modal.Header closeButton style={{border: "none"}}>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
                    commodi aspernatur enim, consectetur. Cumque deleniti temporibus
                    ipsam atque a dolores quisquam quisquam adipisci possimus
                    laboriosam. Quibusdam facilis doloribus debitis! Sit quasi quod
                    accusamus eos quod. Ab quos consequuntur eaque quo rem! Mollitia
                    reiciendis porro quo magni incidunt dolore amet atque facilis ipsum
                    deleniti rem!
                </p>
            </Modal.Body>
            
            </Col>
        </Row>
        
    </Modal>
)
export default BirchModal
